<!-- 
  @Time：       2021年08月11日09:39:56
  @By：         haley
  @list：       列表数据
  @idx：        点击的图片的下标
  @mediaType：  默认不传值为pc端，手机端（'m'）需要处理弹框尺寸 
 -->
<template>
    <div class="zoom">
		<div class="zoom_box" :class="{m:mediaType=='m'}">
			<!-- 关闭按钮 -->
			<div class="zoom_box_close" @click="hideModal">
				<img src="@/assets/close.png" alt="" class="img">
			</div>
			<!-- 内容 -->
			<div class="zoom_box_con">
				<img :src="list[idxIn]" alt="" class="img">
			</div>
            <!-- 左右切换按钮 -->
            <div class="zoom_box_btns">
                <img src="@/assets/pre.png" alt="" class="img disabled" v-if="noPrev">
                <img src="@/assets/pre.png" alt="" class="img" @click="prev" v-else>
                <img src="@/assets/next.png" alt="" class="img disabled" v-if="noNext">
                <img src="@/assets/next.png" alt="" class="img" @click="next" v-else>
            </div>
		</div>
	</div>
</template>
<script>
export default {
    props:{
        list:{
            type:Array
        },
        idx:{
            type:Number,
            default:0
        },
        mediaType:{
            type:String,
            default:''
        }
    },
    computed:{
        noPrev(){
            if(this.idxIn<=0){
                return true
            }else{
                return false
            }
        },
        noNext(){
            if(this.idxIn>=this.list.length-1){
                return true
            }else{
                return false
            }
        }
    },
    data(){
        return{
            isShow:false,
            idxIn:0,
        }
    },
    mounted(){
        this.idxIn=this.idx
    },
    methods:{
        hideModal(){
            this.$emit('hideModal')
        },
        next(){
            !this.noNext&&this.idxIn++
        },
        prev(){
            !this.noPrev&&this.idxIn--
        },
    }
}
</script>
<style lang="scss">
    .zoom{
        position: fixed;
        z-index: 999999;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        background-color: rgba(0,0,0,.6);
        display: flex;
        justify-content: center;
        align-items: center;
        &_box{
            width: 50vw;
            height: 67.6vh;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            &_close{
                position: absolute;
                top:0;
                right:-.6rem;
                width: .5rem;
                height: .5rem;
                cursor: pointer;
                background-color: #fff;
                border-radius: 50%;
                padding: .04rem;
                .img{
                    width: 100%;
                    &:first-child{
                        margin-right: .4rem;
                    }
                }
            }
            &_con{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .img{
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            // 按钮
            &_btns{
                position: absolute;
                bottom:-.6rem;
                left:50%;
                margin-left:-.5rem;
                .img{
                    width: .4rem;
                    cursor: pointer;
                    &:first-child{
                        margin-right: .2rem;
                    }
                    &.disabled{
                        opacity: .3;
                        cursor: not-allowed;
                    }
                }
            }
            &.m{
                width: 90vw;
                height: 40vh;
                .zoom_box_close{
                    top:.2rem;
                    right:-.3rem;
                    width: .8rem;
                    height: .8rem;
                }
                .zoom_box_btns{
                    margin-left: -1rem;
                    width: 2rem;
                    display: flex;
                    justify-content: space-between;
                    .img{
                        width: .8rem;
                        height: .8rem;
                    }
                }   
            }
        }
    }
</style>