<template>
<div class="exhibitionDetail">
    <!--我要咨询-->
    <div class="tableMainBody" v-if="showTable">
        <div class="tableMain">
            <Form :model="form" :label-width="100" ref="form" :rules="ruleValidate" label-position="left">
                <FormItem prop="name" label="姓名：">
                    <Input v-model="form.name" placeholder="请输入姓名" maxlength="50" />
                </FormItem>
                <FormItem prop="tel" label="手机：">
                    <Input v-model="form.tel" placeholder="请输入手机号码" maxlength="20" />
                </FormItem>
                <FormItem prop="company" label="公司名称：">
                    <Input v-model="form.company" placeholder="请输入公司名称" maxlength="100" />
                </FormItem>
                <FormItem prop="email" label="邮箱：">
                    <Input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
                </FormItem>
                <FormItem class="textarea" prop="companyAddress" label="公司地址：">
                    <Input v-model="form.companyAddress" placeholder="请输入公司地址" type='textarea' :rows="2" maxlength="255" />
                </FormItem>
                <FormItem class="textarea" prop="product" label="产品：">
                    <Input v-model="form.product" placeholder="请输入产品" type='textarea' :rows="2" maxlength="255" />
                </FormItem>
                <div class="submit">
                    <Button type="primary" class="qx" @click='showTable=false'>取消</Button>
                    <Button type="primary" class="tj" @click='handleSubmit'>立即提交</Button>
                </div>
                <FormItem class="last" :labelWidth="0">
                    <p class="tips">保护条款：我们一贯重视隐私权及公司信息的保护，为方便与您取得联系，我们会收集您/您公司的相关信息，我们不会将这些信息透露给任何第三方。</p>
                </FormItem>
            </Form>
        </div>
    </div>
    <!-- 下载留资 -->
    <!-- 弹框 -->
    <section v-show="showleaveTable" class="formModal">
        <FormContact @cancel='showleaveTable=false'></FormContact>
    </section>
    <!-- 		<div class="tableMainBody" v-if="showleaveTable">
        <div class="tableMain">
            <Form :model="forms" :label-width="120" ref="forms" :rules="ruleValidates">
                <FormItem prop="name" label="姓名：">
                    <Input v-model="forms.name" placeholder="请输入姓名" maxlength="50" />
                </FormItem>
                <FormItem prop="tel" label="手机号码：">
                    <Input v-model="forms.tel" placeholder="请输入手机号码" maxlength="20" />
                </FormItem>
                <FormItem prop="email" label="邮箱：">
                    <Input v-model="forms.email" placeholder="请输入邮箱" maxlength="50" />
                </FormItem>
                <FormItem prop="company" label="企业名称：">
                    <Input v-model="forms.company" placeholder="请输入企业名称" maxlength="100" />
                </FormItem>
                <FormItem class="textarea" prop="consult" label="咨询事宜：">
                    <Input v-model="forms.consult" placeholder="请输入咨询事宜" type='textarea' :rows="2" maxlength="255" />
                </FormItem>
                <div class="submit">
                    <Button type="primary" class="qx" @click='showleaveTable=false'>取消</Button>
                    <Button type="primary" class="tj" @click='submit'>立即提交</Button>
                </div>

<FormItem class="last" :labelWidth="0">

    <p class="tips">保护条款：我们一贯重视隐私权及公司信息的保护，为方便与您取得联系，我们会收集您/您公司的相关信息，我们不会将这些信息透露给任何第三方。</p>

</FormItem>
            </Form>
        </div>
    </div> -->
    <!-- 顶部图 logo 中英文标题 -->
    <div class="picMain" v-if="config.img&&config.img.isShow">
        <img v-if="dataList.bannerImgUrl && dataList.bannerImgUrl.length>0" v-lazy="dataList.bannerImgUrl" :key="dataList.bannerImgUrl" class="lazyPic" lazy="loading" />
        <!--  <div class="wCenter">

   <div class="picLeft">

<p class="title">{{dataList.nameCn?dataList.nameCn:"暂无中文名称"}}</p>

<p class="title">{{dataList.nameEn?dataList.nameEn:"暂无英文名称"}}</p>

</div>

<div class="picRight">

<img v-lazy="dataList.logoUrl" :key="dataList.logoUrl" class="lazyPic" lazy="loading"/>

</div> 

</div> -->
    </div>
    <!-- 左侧图 右侧标题时间等 -->
    <div class="content">
        <div class="wCenter">
            <div class="contentTop">
                <div class="left">
                    <img v-lazy="dataList.logoUrl" :key="dataList.logoUrl" class="lazyPic" lazy="loading" />
                </div>
                <div class="right">
                    <p class="titleEn">{{dataList.nameEn}}</p>
                    <p class="title"><strong>{{dataList.nameCn}}</strong></p>
                    <p class="time">{{dataList.startTime}} 至 {{dataList.endTime}}</p>
                    <div class="btn" @click="showTable=true">我要咨询</div>
                </div>
            </div>
            <div class="contentBottom">
                <p class="address" v-if="dataList.address||dataList.exhibitionHallTitle">
                    <!-- <img src="../../assets/location.png" /> -->
                    展会地点：
                    <span v-if="dataList.address">{{dataList.address}}</span> <span v-if="dataList.exhibitionHallTitle">{{dataList.exhibitionHallTitle}}</span>
                </p>
                <p class="address" v-if="dataList.website">
                    <!-- <img src="../../assets/address.png" /> -->
                    展会网址：
                    <a :href="dataList.website" target="_blank">
                        {{dataList.website | httpFilter}}
                    </a>
                </p>
                <p class="address">展会周期：{{dataList.exhibitionCycleTitle?dataList.exhibitionCycleTitle:"暂无展会周期"}}</p>
            </div>
            <div class="contentBottom" v-html="dataList.content"></div>
        </div>
    </div>
    <!-- 展会资料 -->
    <div v-if="dataList.exhibitionFileList&&dataList.exhibitionFileList.length>0">
        <div class="titleT">
            <div class="wCenter">展会资料</div>
        </div>
        <div class="downLoad">
            <div class="wCenter">
                <swiper :options="swiperOptionFileMobile" ref="mySwiper" v-if="isMobile">
                    <swiper-slide v-for="(item,index) in dataList.exhibitionFileList" :key="index">
                        <div class="downLoadItem" @click="leaveMessage=='1'?'':showleaveTable=true">
                            <a :href="item.url" v-if="leaveMessage=='1'">
                                <div class="downloadTop">
                                    <div>{{item.type}}</div>
                                </div>
                                <p class="title">{{item.name}}</p>
                            </a>
                            <a v-else>
                                <div class="downloadTop">
                                    <div>{{item.type}}</div>
                                </div>
                                <p class="title">{{item.name}}</p>
                            </a>
                        </div>
                    </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
                <swiper :options="swiperOptionFilePc" ref="mySwiper" v-else>
                    <swiper-slide v-for="(item,index) in dataList.exhibitionFileList" :key="index">
                        <div class="downLoadItem" @click="leaveMessage=='1'?'':showleaveTable=true">
                            <a :href="item.url" v-if="leaveMessage=='1'">
                                <div class="downloadTop">
                                    <div>{{item.type}}</div>
                                </div>
                                <p class="title">{{item.name}}</p>
                            </a>
                            <a v-else>
                                <div class="downloadTop">
                                    <div>{{item.type}}</div>
                                </div>
                                <p class="title">{{item.name}}</p>
                            </a>
                        </div>
                    </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
            </div>
        </div>
    </div>
    <!-- 市场概况 -->
    <div class="titleT">
        <div class="wCenter">市场概况</div>
    </div>
    <div class="textMain">
        <div class="wCenter">
            <p class="text" v-html="dataList.marketContent"></p>
        </div>
    </div>
    <!-- 展会图片 -->
    <div v-if="dataList.exhibitionPhotoList&&dataList.exhibitionPhotoList.length>0">
        <div class="titleT">
            <div class="wCenter">展会图片</div>
        </div>
        <div class="exhibitionPic">
            <div class="wCenter exhibitionSw">
                <swiper :options="swiperOptionsImgMobile" ref="mySwiper" v-if="isMobile" v-lazy-container="{ selector: 'img' }">
                    <swiper-slide v-for="(item,index) in dataList.exhibitionPhotoList" :key="index">
                        <div class="picItem picCenter">
                            <img :data-src="item.url" lazy="loading" width="100%" class="lazyPic" preview="index"  @click="handleClickSwiper(index,'m')"/>
                        </div>
                    </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
                <swiper :options="swiperOptionsImgPc" ref="mySwiper" v-else v-lazy-container="{selector: 'img' }">
                    <swiper-slide v-for="(item,index) in dataList.exhibitionPhotoList" :key="index" class="hand">
                        <div class="picItem picCenter">
                            <img :data-src="item.url" lazy="loading" width="100%" class="lazyPic" @click="handleClickSwiper(index)"/>
                        </div>
                    </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
            </div>
        </div>
    </div>
    <!-- 展会视频 -->
    <div v-if="dataList.exhibitionVideoList&&dataList.exhibitionVideoList.length>0">
        <div class="titleT">
            <div class="wCenter">展会视频</div>
        </div>
        <div class="exhibitionVideo">
            <div class="wCenter">
                <div class="videoItem" v-for="(item,index) in dataList.exhibitionVideoList" :key="index">
                    <video controls>
                        <source :src="item.url" type="video/mp4" />您的浏览器不支持
                        HTML5 video 标签。
                    </video>
                    <p>{{item.name}}</p>
                </div>

            </div>
        </div>
    </div>
    <!-- 展品范围 -->
    <div v-if="dataList.exhibitionScopeList&&dataList.exhibitionScopeList.length>0">
        <div class="titleT">
            <div class="wCenter">展品范围</div>
        </div>
        <div class="scope">
            <div class="wCenter">
                <div v-for="(item,index) in dataList.exhibitionScopeList" :key="index">
                    <p class="name" v-if="item.name">{{item.name}}</p>
                    <p class="text">{{item.content}}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- 参展联络 -->
    <div v-if="dataList.exhibitionContactList&&dataList.exhibitionContactList.length>0">
        <div class="titleT">
            <div class="wCenter">参展联络</div>
        </div>

        <div class="exhibitorPanter">
            <div class="wCenter">
                <div class="content">
                    <div class="item" v-for="(item,index) in dataList.exhibitionContactList" :key="index">
                        <p class="title">{{item.companyName}}</p>
                        <p class="name">
                            {{item.name}}
                        </p>
                        <p class="text" v-if="item.phone">
                            <img src="../../assets/contentUs2.png" />{{item.phone}}
                        </p>
                        <p class="text" v-if="item.email">
                            <img src="../../assets/contentUs3.png" /><a :href="'mailto:'+item.email">{{item.email}}</a>
                        </p>

                        <p class="text" v-if="item.address">
                            <img src="../../assets/contentUs1.png" />{{item.address}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 合作伙伴 -->
    <div v-if="dataList.exhibitionPartnerList&&dataList.exhibitionPartnerList.length>0">
        <div class="titleT">
            <div class="wCenter">合作伙伴</div>
        </div>
        <div class="exhibitorPanter">
            <div class="wCenter">
                <div class="content">
                    <div class="item" v-for="(item,index) in dataList.exhibitionPartnerList" :key="index">
                        <p class="title">{{item.companyName}}</p>
                        <p class="name">
                            {{item.name}}
                        </p>
                        <p class="text" v-if="item.phone">
                            <img src="../../assets/contentUs2.png" />{{item.phone}}
                        </p>
                        <p class="text" v-if="item.email">
                            <img src="../../assets/contentUs3.png" /><a :href="'mailto:'+item.email">{{item.email}}</a>
                        </p>

                        <p class="text" v-if="item.address">
                            <img src="../../assets/contentUs1.png" />{{item.address}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>
	<!-- 放到弹框 -->
	<div v-if="isShowModal">
		<Zoom :list='zoomList' :idx="zoomIdx" @hideModal="isShowModal=false" :mediaType="mediaType"></Zoom>
	</div>
</div>
</template>

<script>
import {
    getExhibitionDetail,
    bookingSubmit,
    leaveAskSubmit,
    getPageConfigByCode,
} from '@/api/api'
import FormContact from '@/components/form-contact'
import Zoom from '@/components/zoom'

import Cookies from 'js-cookie'
export default {
    name: 'home',
    components: {
		FormContact,
		Zoom,
    },
    computed: {
        isMobile() {
            return this.$store.state.app.isMobile;
        },
    },
    filters: {
        httpFilter: function (value) {
            value = value.replace(/^(http(s)?)(:\/\/)/, '')
            value = value.replace(/(.*)(\/){1}$/, '$1');
            return value;
        }
    },
    data() {
        return {
            config: {},
            // 展会资料-mobile
            swiperOptionFileMobile: {
                slidesPerView: 2,
                spaceBetween: 30,
                // centeredSlides: false,
                // autoplay: {
                //     delay: 2500,
                // },
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
            // 展会资料-mobile
            swiperOptionFilePc: {
                slidesPerView: 4,
                spaceBetween: 30,
                // centeredSlides: true,
                // autoplay: {
                //     delay: 2500,
                // },
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
            // 展会图片-mobile
            swiperOptionsImgMobile: {
                slidesPerView: 2,
                spaceBetween: 20,
                // grabCursor: true,
                // centeredSlides: false,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                // loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
            // 展会图片-pc
            swiperOptionsImgPc: {
                slidesPerView: 3,
                spaceBetween: 30,
                // grabCursor: true,
                // centeredSlides: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                //loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            },
            leaveMessage: '0',
            showleaveTable: false,
            showTable: false,
            dataList: '',
            forms: {
                name: '',
                email: '',
                company: '',
                consult: '',
                source: 1,
                tel: ''
            },
            ruleValidates: {
                name: [{
                    required: true,
                    message: '请填写姓名',
                    trigger: 'blur'
                }],
                email: [{
                        required: true,
                        message: "请输入邮箱",
                        trigger: "blur"
                    },
                    {
                        validator: (rule, value, callback) => {
                            const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                            if (value && !reg.test(value)) {
                                callback(new Error("邮箱格式不正确"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    }
                ],
                company: [{
                    required: true,
                    message: '请输入企业名称',
                    trigger: 'blur'
                }],
                consult: [{
                    required: true,
                    message: '请输入咨询事宜',
                    trigger: 'blur'
                }],
                tel: [{
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur"
                    },
                    {
                        validator: (rule, value, callback) => {
                            const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                            if (value && !reg.test(value)) {
                                callback(new Error("手机号格式不正确"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    }
                ],
            },
            form: {
                name: '',
                tel: '',
                company: '',
                email: '',
                companyAddress: '',
                product: '',
                exhibitionId: '',
            },
            ruleValidate: {
                name: [{
                    required: true,
                    message: '请填写姓名',
                    trigger: 'blur'
                }],
                tel: [{
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                            if (value && !reg.test(value)) {
                                callback(new Error("手机号格式不正确"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    }
                ],
                company: [{
                    required: true,
                    message: '请输入公司名称',
                    trigger: 'blur'
                }],
                email: [{
                    required: true,
                    message: '请输入邮箱',
                    trigger: 'blur'
                }, {
                    validator: (rule, value, callback) => {
                        const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        if (value && !reg.test(value)) {
                            callback(new Error("邮箱格式不正确"));
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur"
                }],

                // product: [{
                //     required: true,
                //     message: '请输入产品',
                //     trigger: 'blur'
                // }],
			},
			isShowModal:false,//显示放大弹框
			zoomIdx:0,//放大显示的图片下标
			zoomList:[],//放大图的列表
            mediaType:'',//默认不传值为pc端，手机端（'m'）需要处理弹框尺寸 
        }
    },
    mounted() {
        this.init()
        //从Cookies里获取留资状态为1时表示已留资
        this.leaveMessage = Cookies.get('isSubmit')
    },
    methods: {
        init() {
            this.getPageConfigByCode()
			this.getExhibitionDetail();
		},
		initZoomList(){
			this.zoomList=this.dataList&&this.dataList.exhibitionPhotoList&&this.dataList.exhibitionPhotoList.length>0&&this.dataList.exhibitionPhotoList.map(item=>{
				return item.url
			})
		},
        handleClickSwiper(index,media) {
			this.isShowModal=true
			this.zoomIdx=index
            this.mediaType=media
		},
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'exhibitorDetail/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                }
            })
        },
        getExhibitionDetail() {
            getExhibitionDetail(this.$route.query.id).then(res => {
                if (res.success) {
					this.dataList = res.result;
					this.initZoomList()
                }
            })
        },
        //我要咨询
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.form.exhibitionId = this.dataList.id;
                    bookingSubmit(this.form).then(res => {
                        if (res.success) {
                            this.$refs.form.resetFields();
                            this.$Message.success('提交成功!');
                            this.showTable = false
                        } else {
                            this.$Message.error('提交失败!');
                        }
                    })
                }
            })
        },
        //下载留资
        submit() {
            this.$refs.forms.validate((valid) => {
                if (valid) {
                    leaveAskSubmit(this.forms).then(res => {
                        if (res.success) {
                            this.$Message.success('提交成功!');
                            Cookies.set('isSubmit', '1', {
                                expires: 7
                            });
                            this.showleaveTable = false;
                            this.$router.go(0);
                        } else {
                            this.$Message.error('提交失败!');
                        }
                    })
                }
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.exhibitionDetail {
    .picMain {
        width: 19rem;
        // height: 3rem;
        // background: url(../../assets/exhPic.png);
        // background-size: 100% 100%;
        // padding: 0.5rem 0;

        @media (max-width: 767px) {
            width: 100%;
            // background: url(../../assets/exhWap.png);
        }

        .wCenter {
            display: flex;
        }

        .picLeft {
            width: 5rem;

            @media (max-width: 767px) {
                width: 100%;
            }

            .title {
                color: #ffffff;
                font-size: 0.3rem;
                font-weight: bold;
                margin-bottom: 0.3rem;

                @media (max-width: 767px) {
                    margin-bottom: 0.1rem;
                }
            }

            .text {
                color: #ffffff;
                font-size: 0.24rem;
                line-height: 1.6;
                overflow: hidden;
                /*text-overflow: ellipsis; 有些示例里需要定义该属性，实际可省略*/
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                height: 1.2rem;

                @media (max-width: 767px) {
                    height: 1.6rem;
                    font-size: 0.12rem;
                }
            }
        }

        .picRight {
            margin-left: 1.3rem;
            width: 1.85rem;
            height: 1.85rem;
            background: #ffffff;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            @media (max-width: 767px) {
                margin-left: 0.5rem;
                width: 1.6rem;
                height: 1.6rem;
                margin-top: 0.5rem;
                flex: 0 0 1.6rem;
            }

            img {

                max-width: 90%;
                max-height: 90%;

                @media (max-width: 767px) {
                    max-width: 90%;
                    max-height: 90%;
                }
            }
        }
    }

    .contentTop {
        margin-top: 0.5rem;
        display: flex;
        margin-bottom: 0.3rem;

        @media (max-width: 767px) {
            display: block;
            margin-top: 0rem;
        }

        .left {
            flex: 0 0 2.2rem;
            width: 2.2rem;
            height: 2.2rem;
            margin-right: 0.42rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.01rem solid #e1e1e1;

            @media (max-width: 767px) {
                width: 40%;
                margin: 0 auto .4rem;
                height: auto;
                padding: .2rem 0;
            }

            img {
                width: auto !important;
                max-width: 90%;
                max-height: 90%;

                @media (max-width: 767px) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .right {
            @media (max-width: 767px) {
                width: 100%;
            }

            .title {
                color: #333333;
                font-size: 0.28rem;
                margin-bottom: 0.1rem;

                @media (max-width: 767px) {
                    font-size: 0.4rem;
                    margin-bottom: 0.2rem;
                }
            }

            .titleEn {
                color: #333333;
                font-size: 0.22rem;
                margin-bottom: 0.05rem;

                @media (max-width: 767px) {
                    font-size: 0.4rem;
                    margin-bottom: 0.2rem;
                }
            }

            .time {
                color: #aaaaaa;
                font-size: 0.18rem;
                margin-bottom: 0.5rem;

                @media (max-width: 767px) {
                    font-size: 0.36rem;
                }
            }

            .address {
                color: #333333;
                font-size: 0.2rem;
                margin-bottom: 0.2rem;

                a {
                    color: unset;
                }

                img {
                    width: 0.21rem;
                    position: relative;
                    top: 0.04rem;
                    margin-right: 0.1rem;
                }
            }

            .btn {
                width: 2.4rem;
                height: 0.53rem;
                line-height: 0.53rem;
                text-align: center;
                background-image: url(../../assets/bannerBtn.png);
                background-size: 100% 100%;
                font-size: 0.24rem;
                color: #ffffff;
                cursor: pointer;

                @media (max-width: 767px) {
                    width: 2.8rem;
                    height: 0.7rem;
                    line-height: 0.7rem;
                    font-size: 0.38rem;
                }
            }
        }
    }

    .contentBottom {
        color: #333333;
        font-size: 0.2rem;
        line-height: 1.6;
        text-align: justify;

        @media (max-width: 767px) {
            font-size: 0.4rem;
        }

        .address {
            margin-bottom: 0.1rem;

            @media (max-width: 767px) {
                font-size: 0.4rem;

            }
        }

        .address:last-child {
            margin-bottom: 0.2rem;
        }
    }

    .titleT {
        color: #ff1932;
        font-size: 0.24rem;
        margin: 0.2rem 0 .1rem;

        @media (max-width: 767px) {
            font-size: 0.4rem;
            margin: 0.2rem 0 0.1rem;

        }
    }

    .exhibitionPic {
        margin-bottom: 0.3rem;
    }

    .downLoad {

        .downLoadItem {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
            }

            cursor: pointer;

            .downloadTop {
                width: 0.61rem;
                height: 0.75rem;
                background: url(../../assets/download.png);
                background-size: 100% 100%;
                position: relative;

                div {
                    position: absolute;
                    bottom: 0.02rem;
                    left: 0;
                    width: 100%;
                    text-align: center;
                    font-size: 0.2rem;
                    color: #ffffff;
                }
            }

            .title {
                color: #333333;
                font-size: 0.18rem;
                margin: 0.26rem 0 0 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                max-width: 90%;
                text-align: center;

                a {
                    color: unset;
                }
            }

        }
    }

    .textMain {
        .text {
            font-size: 0.2rem;
            color: #333333;
            line-height: 1.6;
            text-align: justify;

            @media (max-width: 767px) {
                font-size: 0.4rem;

            }
        }
    }

    //   展会图片
    .exhibitionPic {
        .wCenter {
            display: flex;
            flex-wrap: wrap;
        }

        .picItem {
            border-radius: 0.1rem;
            overflow: hidden;

            &:nth-child(3n) {
                margin-right: 0rem;
            }

            @media (max-width: 767px) {
                $w: 49%;
                $gutter: (100% - $w * 2);
            }

            img {

                display: block;
                border-radius: 0.1rem;
            }
        }
    }

    .exhibitionVideo {
        .wCenter {
            display: flex;
            flex-wrap: wrap;
        }

        .videoItem {
            width: 48%;
            margin-right: 2%;

            video {
                width: 100%;
                height: 4rem;
            }

            p {
                color: #333333;
                font-size: 0.2rem;
                margin: 0.15rem 0;
            }
        }

        @media (max-width: 767px) {
            .videoItem {
                width: 100%;

            }
        }
    }

    .exhibitorContent {
        .wCenter {
            display: flex;
            flex-wrap: wrap;
        }

        .contentItem {
            display: flex;
            margin-bottom: 0.4rem;
            margin-right: 0.5rem;

            .left {
                width: 1.75rem;
                height: 2.4rem;
                margin-right: 0.4rem;

                img {
                    width: 1.75rem;
                    height: 2.4rem;
                }
            }

            .right {
                .name {
                    color: #333333;
                    font-size: 0.24rem;
                    margin-bottom: 0.1rem;
                }

                .text {
                    color: #333333;
                    font-size: 0.2rem;
                    margin-bottom: 0.1rem;
                    text-align: justify;
                }
            }
        }
    }

    .wCenter .swiper-container {
        margin: 0 1rem;
        position: static;
    }

    .wCenter {
        position: relative;
    }

    .content {
        display: flex;
        flex-wrap: wrap;

        @media (max-width:767px) {
            margin-top: 0rem;
        }

        .item {
            padding: 0 0 0.15rem;
            width: 32%;
            margin: 0 0.2rem 0.2rem 0;
            // background: url('../../assets/contextBg.png');
            border-radius: 0.1rem;
            background-size: 100% 100%;

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-last-child(1),
            &:nth-last-child(2),
            &:nth-last-child(3) {
                margin-bottom: 0;

            }

            @media (max-width: 767px) {

                $w: 100%;
                $gutter: (100% - $w * 2);
                width: $w;
                margin-bottom: 0.3rem;

                &:nth-last-child(1),
                &:nth-last-child(2),
                &:nth-last-child(3) {
                    margin-bottom: 0.3rem;
                }

                &,
                &:nth-child(3n) {
                    margin: 0 $gutter $gutter 0;
                    margin: 0;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            .name {
                color: #333333;
                font-size: 0.18rem;
                margin: 0.1rem 0;
                font-weight: bold;

                @media (max-width:767px) {
                    font-size: 0.36rem;
                }
            }

            .title {
                color: #333333;
                font-size: 0.2rem;
                margin-bottom: 0.1rem;
                font-weight: bold;

                @media (max-width: 767px) {
                    font-size: 0.38rem;
                }
            }

            .line {
                background-color: #333333;
                width: 0.5rem;
                height: 0.05rem;
                margin: 0.15rem 0;
            }

            .text {
                color: #333333;
                font-size: 0.18rem;
                margin-bottom: 0.1rem;

                @media (max-width:767px) {
                    font-size: 0.36rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: unset;
                }

                img {
                    width: 0.2rem;
                    position: relative;
                    top: 0.05rem;
                    margin-right: 0.06rem;

                    @media (max-width:767px) {
                        width: 0.4rem;
                    }
                }
            }
        }
    }

    .scope {
        .name {
            color: #FD8F68;
            font-size: .2rem;
            line-height: 1.8;

            @media (max-width: 767px) {
                font-size: 0.38rem;
            }
        }

        .text {
            color: #333333;
            font-size: 0.2rem;
            margin-bottom: 0.1rem;
            line-height: 1.6;

            @media (max-width: 767px) {
                font-size: 0.38rem;
            }
        }
    }

}

.tableMain {
    position: fixed;
    left: 50%;
    top: 5%;
    margin-left: -4rem;
    width: 8rem;
    background: #eeeeee;
    padding: 0.5rem 0.6rem;
    margin-top: 0.6rem;

    .title {
        color: #333333;
        font-size: 0.24rem;
        margin-bottom: 0.3rem;
        margin-top: 0;
    }

    .ivu-form {
        @media (max-width: 767px) {
            display: block;
        }
    }

    .ivu-form-item {
        @media (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .product {
        width: 9.1rem;
    }

    .submit {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 0.3rem;

        @media (max-width: 767px) {
            justify-content: center;
        }

        .qx {
            margin-right: .1rem;
            width: 2rem;
            height: 0.5rem;
            background-color: #000000;
            border-color: #000000;

            @media (max-width: 767px) {
                width: 100%;
                height: 0.5rem * 1.5;
            }
        }

        .tj {
            margin-right: .1rem;
            width: 2rem;
            height: 0.5rem;
            background-color: #ff1932;
            border-color: #ff1932;

            @media (max-width: 767px) {
                width: 100%;
                height: 0.5rem * 1.5;
            }
        }
    }

    .ivu-form-item:nth-child(odd) {

        @media (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.tableMainBody {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.titleMain {
    color: #333333;
    font-size: 0.3rem;
    margin: 0rem 0 0.2rem 0;

    @media (max-width: 767px) {
        margin: 0.8rem 0 0.2rem 0;
    }
}
</style>
<style lang="scss" scoped>
.exhibitionDetail {
    ::v-deep .ivu-input {
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .textArea ::v-deep .ivu-input {
        height: auto !important;

        @media (max-width: 767px) {
            height: auto !important;
        }
    }
}
</style>